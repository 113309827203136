import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // baseURL: 'http://f.com/',
    baseURL: 'https://b.fengshenghmd.cn/',
    isCollapse: false, //侧边导航栏折叠状态
    authList: [],
    user:{
      
    },
    forum_type: '',
    menuFormVisible: true,
    speech_url: ''
  },
  mutations: {
    edit_auth_list(state, data) {
      state.authList = data
    },
    edit_is_collapse(state,data) {
      state.isCollapse = data
    },
    edit_user(state,data) {
      state.user = data
    },
    edit_forum_type(state, data) {
      state.forum_type = data
    },
    edit_menuFormVisible(state, data) {
      state.menuFormVisible = data
    },
    edit_speech_url(state, speech) {

      if(speech.length) {

        const randomIndex = Math.floor(Math.random() * speech.length)
        const speech_url = speech[randomIndex].url
        state.speech_url = ''
        state.speech_url = speech_url
        // let x = 0
        // let randomIndex = 0
        // let speech_url = ''
        // //解决值未变化导致不播放的问题
        // do {
        //   //随机索引
        //     randomIndex = Math.floor(Math.random() * speech.length)
        //     speech_url = speech[randomIndex].url
        //     console.log(x);
        //     x+=1
        // } while ((speech_url === state.speech_url) && (x<10))
        
        //   state.speech_url = ''
        //   state.speech_url = speech_url
      } else {
        state.speech_url = ''
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
